import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import axios from "axios";
import { apis } from "../../apis";
import { Autocomplete, Chip, Skeleton, TextField, Tooltip } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { get_company } from "../../redux/slice/createcompany";
import { get_facility } from "../../redux/slice/facility";
import SelectSingle from "../../components/input/Select";
import TableSection from "./table";
import EnhancedTable from "./EnhancedTable";
import { get_twilio } from "../../redux/slice/createtwilio";
import { get_twiliofacilityphone } from "../../redux/slice/twiliofacilityphone";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { AutoComplete } from "rsuite";
import ClearIcon from '@mui/icons-material/Clear';
import { PiArrowClockwiseBold } from "react-icons/pi";
import "../../module_css/facility.css"

const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'name', label: 'Name', isSort: true },
    { id: 'company', label: 'Company', isSort: true },
    { id: 'timezone', label: 'Timezone', isSort: true },
    { id: 'address', label: 'Address', isSort: true },
    { id: 'city', label: 'City', isSort: true },
    { id: 'state', label: 'State', isSort: true },
    { id: 'zip', label: 'Zip', isSort: true },
    { id: 'locationId', label: 'Location Id', isSort: true },
    { id: 'twiliocredid', label: 'Twilio Accounts', isSort: true },
    { id: 'phone', label: 'Twilio Messaging Phone', isSort: true },
    { id: 'action', label: 'Action', align: 'center', isSort: false },
];

export default function FacilityTable({ }) {
    const [updateModel, setUpdateModel] = useState(false);
    const [FormData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const facility = useSelector(state => state.facility)
    const company = useSelector(state => state.company)
    const twiliocredid = useSelector(state => state?.twilio)
    const [filterCompany, setFilterCompany] = useState([])
    const twiliofacilityphoneid = useSelector(state => state?.twiliofacilityphone)
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false) // for CURD Operation
    const [isSkeleton, setIsSkeleton] = useState(true) // for fetching data
    const [page, setPage] = useState(0)
    useEffect(() => {
        if (facility?.status) {
            setIsSkeleton(false);
        }
        if (!facility?.status) {
            dispatch(get_facility({ token }));
        }
        if (!company?.status) {
            dispatch(get_company({ token }));
        }
        if (!twiliocredid?.status) {
            dispatch(get_twilio({ token }));
        }
        if (!twiliofacilityphoneid?.status) {
            dispatch(get_twiliofacilityphone({ token }));
        }
    }, [facility?.status, company]);

    useEffect(() => {
        const facilityData = facility?.facility_data || []; // Default to empty array if undefined or null

        const dataRows = facilityData.map((it, key) => {
            return {
                id: facilityData.length - key,
                name: it?.name,
                company: it?.company?.name,
                phone: it?.twiliofacilityphoneid?.phone,
                timezone: it?.timezone,
                address: it?.address,
                city: it?.city,
                state: it?.state,
                zip: it?.zip,
                locationId: it?.locationId,
                isAutumnTrack: it?.isAutumnTrack,
                twiliocredid: it?.twiliocredid?.name,
                action: (
                    <>
                        <button className="btn" onClick={() => handleModel(it)}>
                            <TbEdit />
                        </button>
                        {/* <button className="btn" onClick={() => handalDrop(it)}>
                            <RiDeleteBin6Line />
                        </button> */}
                    </>
                ),
            }
        });

        setRows(dataRows.reverse()); // reverse is now safe
    }, [facility]);





    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setFormData({ ...FormData, isAutumnTrack: isChecked }); // Directly update formData's isAutumnTrack
    };


    const handalChange = ({ target }) => {
        const { name, value } = target;
        setFormData({
            ...FormData,
            [name]: value,
        });
    };


    function handleModel(item) {
        setFormData(item);
        setUpdateModel(true);
    };

    const validateForm = () => {
        let isValid = true;
    
        if (!FormData?.name?.trim()) {
            toast.error("Facility Name is required", { position: "top-right" });
            isValid = false;
        }
    
        if (!FormData?.company || (typeof FormData.company === "string" && !FormData.company.trim())) {
            toast.error("Company Name is required", { position: "top-right" });
            isValid = false;
        }
        
    
        if (!FormData?.locationId?.trim()) {
            toast.error("Location ID is required", { position: "top-right" });
            isValid = false;
        }
    
        return isValid;
    };
    

    const handleUpdate = async () => {

        if (!validateForm()) {
            return;
        }
        try {
            setLoading(true)
            const updata = await axios.put(
                apis.FACILITY_UPDATE,
                {
                    id: FormData._id,
                    company: FormData?.company,
                    phone: FormData?.phone,
                    timezone: FormData?.timezone,
                    name: FormData?.name,
                    address: FormData?.address,
                    city: FormData?.city,
                    state: FormData?.state,
                    zip: FormData?.zip,
                    locationId: FormData?.locationId,
                    twiliocredid: FormData?.twiliocredid,
                    isAutumnTrack: FormData?.isAutumnTrack,
                    twiliocredid: FormData?.twiliocredid,
                    twiliofacilityphoneid: FormData?.twiliofacilityphoneid
                },
                {
                    headers: {
                        token: token,
                    },
                }
            );

            toast.success("Facility Successfully updated.", {
                position: "top-right",
            });
            dispatch(get_facility({ token }))
            setTimeout(() => {
                setUpdateModel(false);
                setLoading(false)
            }, 700)
        } catch (error) {
            toast.error("Facility update failed.", {
                position: "top-right",
            });
        } finally {
            setLoading(false)
        }
    };


    // drop Company 
    async function handalDrop(data) {
        try {
            const res = await axios.delete(
                `${apis.FACILITY_DROP}/${data?._id}`,
                {
                    headers: {
                        token: token,
                    },
                }
            );
            dispatch(get_facility({ token }))
        } catch (error) {
            console.log(error)
        }
    }

    const filteredRows = rows
        .filter(row => {
            const searchFields = [row.name, row.company, row.phone, row.timezone, row.address, row.city, row.state, row.zip, row.locationId, row.twiliocredid];
            return searchFields.some(field => field?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
        })
        .map((row, index) => ({
            ...row,
            id: index + 1, // Start the ID from 1
        }));

    const handalChangefilter = (event, value) => {
        setFilterCompany(Array.isArray(value) ? value : [],)
        dispatch(get_facility({ token, value }));
    };



    const handleDelete = (valueToRemove) => {
        const updatedCompany = FormData?.company?.filter(value => value !== valueToRemove);
        handalChangefilter({ target: { name: 'company', value: updatedCompany } });
    };

    const clearFilters = () => {
        setFilterCompany([])
        dispatch(
            get_facility({
                token: token,
                value: "",
            })
        );
    };

    return (
        <>
            {/* Search Input */}
            {
                isSkeleton ? <div className="d-flex justify-content-end">
                    <Skeleton animation="wave" height={60} width={200} />
                </div> :

                    <div
                        className="d-flex justify-content-end align-items-center container mb-2 facilitycontainer"
                        style={{ gap: "1rem" }}
                    >

                        {
                            isSkeleton ? <div className="px-2">
                                <Skeleton variant="rounded" width={100} height={36} />
                            </div> : <>
                                <div className="date-fltr w-15 " onClick={clearFilters}>
                                    <span>
                                        <i className='px-1' style={{ color: "#4C7153" }}><PiArrowClockwiseBold /></i>
                                        Clear filter
                                    </span>
                                </div>
                            </>
                        }


                        <div style={{ width: "17%" }} className="facilityautocomplete" >
                            <Autocomplete
                                multiple
                                size="small"
                                options={
                                    Array.isArray(company?.company_data)
                                        ? [...company.company_data].sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
                                        : []
                                }
                                sx={{ width: "100%" }}
                                getOptionLabel={(option) => option.name || ""}
                                value={Array.isArray(filterCompany) ? filterCompany : []}
                                onChange={handalChangefilter}
                                renderInput={(params) =>

                                    <Tooltip
                                        title={
                                            Array.isArray(filterCompany)
                                                ? filterCompany.map((option) => option.name).join(", ")
                                                : "No Company selected"
                                        }
                                        arrow
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: "1rem",
                                                    padding: "5px",
                                                    backgroundColor: "gray",
                                                    color: "#fff",
                                                    maxWidth: "450px",
                                                    wordWrap: "break-word",
                                                },
                                            },
                                        }}
                                    >
                                        <TextField {...params} label="Company" sx={{ maxWidth: "100%" }} />
                                    </Tooltip>
                                }
                                renderTags={(value, getTagProps) => {
                                    if (value.length > 0) {
                                        return (
                                            <>
                                                <Chip
                                                    sx={{
                                                        maxWidth: "90px",
                                                        width: "90px",
                                                        height: "30px",
                                                        maxHeight: "30px",

                                                    }}
                                                    key={value[0]._id}
                                                    label={value[0].name}
                                                    {...getTagProps({ index: 0 })}
                                                    onDelete={() =>
                                                        handalChangefilter(
                                                            "company",
                                                            value.filter((_, i) => i !== 0)
                                                        )
                                                    }
                                                />
                                                {value.length > 1 ? "..." : ""}
                                            </>
                                        );
                                    }
                                    return null;
                                }}
                            />
                        </div>
                        <div style={{ width: "200px" }} className="facilitysearch">
                            <TextField
                                label="Search"
                                type="search"
                                variant="outlined"
                                size="small"
                                value={searchQuery}
                                onChange={(e) => {
                                    setPage(0);
                                    setSearchQuery(e.target.value);
                                }}
                            />
                        </div>

                    </div>

            }

            <EnhancedTable status={facility?.loading} columns={columns} rows={filteredRows} page={page} setPage={setPage} />
            <Modal
                size="lg"
                show={updateModel}
                onHide={() => setUpdateModel(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Facility
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row ">
                        <div className="col-sm-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label="Name"
                                value={FormData?.name}
                                name="name"
                                onChange={handalChange}
                                sx={{ width: "100%" }}
                            />
                        </div>

                        <div className="col-md-4 mb-4 text-center facilityrow ">
                            {/* <SelectSingle
                                data={
                                    Array.isArray(company?.company_data)
                                        ? [...company.company_data].sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
                                        : []
                                }
                                value={
                                    typeof FormData?.company === "string"
                                        ? FormData?.company
                                        : FormData?.company?._id || ""
                                }
                                name="company"
                                label="Company"
                                handleChange={handalChange}
                            /> */}



<Autocomplete
  options={
    Array.isArray(company?.company_data)
      ? [...company.company_data].sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
      : []
  }
  getOptionLabel={(option) => option?.name || ""}
  value={
    company?.company_data?.find((item) =>
      typeof FormData?.company === "string"
        ? item._id === FormData?.company
        : item._id === FormData?.company?._id
    ) || null
  }
  onChange={(event, newValue) => handalChange({ target: { name: "company", value: newValue?._id || "" } })}
  renderInput={(params) => <TextField {...params} label="Company" variant="outlined" />}
/>

                        </div>
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <SelectSingle data={twiliocredid?.twilio_data} value={typeof FormData?.twiliocredid == "string" ? FormData?.twiliocredid : FormData?.twiliocredid?._id} name="twiliocredid" label="Select Twilio" handleChange={handalChange} />
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label=" City"
                                defaultValue={FormData?.city}
                                onChange={handalChange}
                                name="city"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label=" State"
                                defaultValue={FormData?.state}
                                onChange={handalChange}
                                name="state"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <SelectSingle
                                showPhone={true}
                                data={twiliofacilityphoneid?.data?.data?.filter(item => item?.twilio_creds?._id == (typeof FormData?.twiliocredid == "string" ? FormData?.twiliocredid : FormData?.twiliocredid?._id))}
                                value={typeof FormData?.twiliofacilityphoneid === "string" ? FormData?.twiliofacilityphoneid : FormData?.twiliofacilityphoneid?._id}
                                name="twiliofacilityphoneid"
                                label="Select Twillio Messaging phone"
                                handleChange={handalChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label="Timezone"
                                defaultValue={FormData?.timezone}
                                onChange={handalChange}
                                name="timezone"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label="Zip"
                                defaultValue={FormData?.zip}
                                onChange={handalChange}
                                name="zip"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-4 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label="Location Id"
                                defaultValue={FormData?.locationId}
                                onChange={handalChange}
                                name="locationId"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-sm-12 mb-4 text-center facilityrow">
                            <TextField
                                id="outlined-required"
                                label=" Address"
                                defaultValue={FormData?.address}
                                onChange={handalChange}
                                name="address"
                                sx={{ width: "100%" }}
                            />
                        </div>


                    </div>
                    <div className="row">
                    </div>
                    <div className="py-3 px-2 border  rounded-2 d-flex justify-content-between align-items-center" style={{
                        width: "100%",
                    }} >
                        Is Autumn Track
                        <div className="form-check form-switch d-flex justify-content-center">
                            <input
                                style={{ cursor: 'pointer' }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                onChange={handleSwitchChange}
                                id="flexSwitchCheckDefault"
                                checked={FormData.isAutumnTrack}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center container">
                        {
                            loading ? <Button variant="success" type="button" disabled>Loading...</Button> : <Button variant="success" onClick={handleUpdate}>Update Facility</Button>
                        }

                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}