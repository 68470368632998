import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { PiNutFill } from "react-icons/pi";
import { apis } from "../../../apis";

export const get_webhook = createAsyncThunk("webhook", async ({ token, filter }, { rejectWithValue }) => {
    try {
        console.log("filterfilter", filter)
        let headers = {
            token: token
        }
        const { data } = await axios.post(`${apis.WEB_HOOK}`,
            {
                punch_string: filter?.punch_string,
                search_agency: filter?.agency_id,
                sent_at_from: filter?.enddate,
                sent_at_to: filter?.startdate,
                page: filter?.page === 0 ? 1 : filter?.page + 1,
                limit: filter?.limit,
            },
            { headers })

        return data

    } catch (error) {
        return rejectWithValue(error.response)
    }

})

const webhook = createSlice({
    name: "webhook",
    initialState: {
        loading: false,
        error: null,
        data: null,
        status: false,
        total: null,
        totalPages: null

    },

    extraReducers: (builder) => {
        builder.addCase(get_webhook.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(get_webhook.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = true
            state.loading = false
            state.total = action.payload?.totalDocuments
            state.totalPages = action.payload?.totalPages
        });
        builder.addCase(get_webhook.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.data = null;
        });
    }
})

const webhook_slice = webhook.reducer
export default webhook_slice
