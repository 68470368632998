import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "../../module_css/multiselectcomponent.css"
import { Autocomplete, TextField, Chip, Tooltip } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelect({ data, name, value, label, handleChange }) {
    const [selectedOptions, setSelectedOptions] = React.useState(value || []);

    const handleSelect = (event, newValue) => {
        setSelectedOptions(newValue);
        handleChange({ name, value: newValue });
    };

    return (
        <Autocomplete
        multiple
        id="size-small-filled"
        size="large"
        options={data}
        getOptionLabel={(option) => option.name}
        value={selectedOptions}
        onChange={handleSelect}
        isOptionEqualToValue={(option, value) => option._id === value._id} // Allows unselect on click
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                variant="outlined"
            />
        )}
        renderTags={(tagValue, getTagProps) => {
            const visibleTags = tagValue.slice(0, 2);
            const moreCount = tagValue.length - 2;
            return [
                ...visibleTags.map((option, index) => (
                    <Chip
                        sx={{
                            maxWidth: "80px",
                            width: "80px",
                            maxHeight: "40px",
                            height: "30px",
                            
                            "& .MuiChip-deleteIcon": {
                                fontSize: "15px",
                            },
                        }}
                        {...getTagProps({ index })}
                        key={option._id}
                        label={option.name}
                    />
                )),
                moreCount > 0 ? (
                    <Tooltip
                        key="more-tooltip"
                        title={tagValue.map(option => option.name).join(", ")}
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: "0.8rem",
                                    padding: "3px",
                                    backgroundColor: "gray",
                                    color: "#fff",
                                    maxWidth: "400px",
                                    wordWrap: "break-word",
                                },
                            },
                        }}
                    >
                        <Chip
                            sx={{
                                maxWidth: "80px",
                                width: "80px",
                                maxHeight: "40px",
                                height: "30px",
                            }}
                            key="more"
                            label={`+${moreCount} more`}
                        />
                    </Tooltip>
                ) : null
            ];
        }}
        renderOption={(props, option, { selected }) => (
            <li 
                {...props} 
                style={{
                    backgroundColor: selected ? "#345d3b" : "white",
                    fontWeight: selected ? "normal" : "normal",
                    color: selected ? "white" : "black", 
                }}
            >
                {option.name}
            </li>
        )}
    />


    );
}