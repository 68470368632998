
export const remove_duplicate_msg = (msg_arr) => {
    const uniquedates = [];

    const filteredMessagesArray = msg_arr.filter((message) => {
        const { year, month, day } = message._id;

        const dateKey = `${year}-${month}-${day}`;

        if (!uniquedates.includes(dateKey)) {
            uniquedates.push(dateKey);
            return true;
        }
        return false;
    });
    return filteredMessagesArray
}

export const mergeMessagesByDate = (messages) => {
    const mergedMessages = {};

    messages.forEach((dayData) => {
        const { _id, messages } = dayData;
        const { year, month, day } = _id;
        const key = `${year}-${month}-${day}`;
        if (!mergedMessages[key]) {
            mergedMessages[key] = {
                _id: { year, month, day },
                messages: [],
            };
        }

        mergedMessages[key].messages = mergedMessages[key].messages.concat(messages);
    });

    return Object.values(mergedMessages);
}


export function convertToTimeZone(utcDateTimeStr, timeZone) {
    try {
        // Parse the UTC date-time string
        const utcDate = new Date(utcDateTimeStr);
        
        // Check if the date is valid
        if (isNaN(utcDate.getTime())) {
            throw new Error("Invalid UTC date-time string");
        }

        // Convert to the specified timezone
        const options = {
            timeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            // hour12: false
            hour12: true 
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(utcDate);
        
        // Extract formatted values
        const dateObj = {};
        parts.forEach(({ type, value }) => {
            dateObj[type] = value;
        });
        
        // Return formatted date-time string
        return `${dateObj.month}/${dateObj.day}/${dateObj.year} ${dateObj.hour}:${dateObj.minute} ${dateObj.dayPeriod}`;

    } catch (error) {
        console.error(error.message);
        return null;
    }
}

// Example usage:
// const utcDateTime = "2024-02-11T12:00:00Z";
// const timeZone = "America/New_York";
// console.log(convertToTimeZone(utcDateTime, timeZone));
