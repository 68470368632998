import React, { useEffect, useState } from 'react'
import Navbar2 from '../NavBar2'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress, Skeleton, Tooltip, IconButton } from '@mui/material'
import { Modal } from "react-bootstrap";
import DynamicTable from '../../components/table/dynamicTable'
import moment from 'moment'
import WebHookFilter from './filter'
import { IoIosGitPullRequest } from "react-icons/io";
import { LuFileJson } from "react-icons/lu";
import { BsMeta } from "react-icons/bs";
import { MdPunchClock } from 'react-icons/md'
const columns = [
    { id: 'id', label: 'Sr No', isSort: true },
    { id: 'agency_name', label: 'Agency Name', isSort: true },
    { id: 'sent_at', label: 'Sent At', isSort: true },
    { id: 'punch', label: 'Punch', isSort: true },
    { id: 'response_status_code', label: 'Response Status Code', isSort: true },
    { id: 'full_request', label: 'Request', isSort: true },
    { id: 'response', label: 'Response', isSort: true },
    { id: 'meta', label: 'Meta', isSort: true },
];
const WebHook = () => {
    const profile = useSelector(state => state.profile)
    const [rows, setRows] = useState([])
    const webHookLogsData = useSelector(state => state?.webhook)
    const [isSkeleton, setIsSkeleton] = useState(true)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [isInfoData, setIsInfoData] = useState();
    const [isInfoHeading, setIsInfoHeading] = useState();
    useEffect(() => {
        if (webHookLogsData?.status && !webHookLogsData?.loading) {
            setIsSkeleton(false)
        }
        const dataRows = webHookLogsData?.data?.data?.map((it, key) => {
            // Format the date and time using toLocaleString
            return {
                id: key + 1,
                agency_name: it?.agencyName || "N/A",
                sent_at: it?.sent_at ? moment(it?.sent_at).utc().format("MM/DD/YYYY hh:mm A") : "N/A" || 'N/A',
                punch: (
                    <Tooltip title={it?.punch_string}>
                        <IconButton>
                            <MdPunchClock size={18} />
                        </IconButton>
                    </Tooltip>
                ),
                response_status_code: it?.response_status_code || "N/A",
                full_request: (
                    <div className="flex items-center justify-center h-screen">
                        <IoIosGitPullRequest
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.full_request), setIsInfoHeading("Full Request Details") }}
                        />
                    </div>
                ),
                response: (
                    <div className="flex items-center justify-center h-screen">
                        <LuFileJson
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.response); setIsInfoHeading("Respose Details") }}
                        />
                    </div>
                ),
                meta: (
                    <div className="flex items-center justify-center h-screen">
                        <BsMeta
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            fontSize="medium"
                            onClick={() => { setOpen(true), setIsInfoData(it?.meta), setIsInfoHeading("Meta Details") }}
                        />
                    </div>
                ),
                punch_date: it?.request_data?.datetime ? moment(it?.request_data?.datetime).utc().format("MM/DD/YYYY hh:mm A") : "N/A",
            }
        })
        setRows(dataRows)
    }, [webHookLogsData])
    return (
        <>
            <Navbar2 />
            {
                webHookLogsData?.loading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <div className="container py-4">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4 d-flex align-items-center">
                        <NavLink to={"/landing-page"} className="text-dark">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </NavLink>
                        <p className="admin-name-choose-sel rounded mb-0 mx-2 py-3 px-4">
                            Webhook logs
                        </p>
                    </div>
                    <div className="col-8 d-flex justify-content-end">
                        <div>
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/dashboard" className={"admin-name-choose-sel text-decoration-none py-3 mx-2 px-4 rounded"}>
                                    Shifts
                                </NavLink>
                            )}
                            {profile?.data?.roles[0]?.name === "admin" && (
                                <NavLink to="/admin" className={"admin-name-choose-sel text-decoration-none py-3 px-4 rounded"}>
                                    Admin Panel
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="dash-main py-3">
                <div className="container shift-filter">
                    <WebHookFilter isSkeleton={isSkeleton} setIsSkeleton={setIsSkeleton} setPage={setPage} page={page} limit={rowsPerPage} />
                </div>
                <div className="container py-3">
                    {
                        !isSkeleton ?
                        <DynamicTable status={webHookLogsData?.status} columns={columns} rows={rows} page={page} totalPage={webHookLogsData?.data?.totalRecords } setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                        :
                            <div>
                                <div className="py-2">
                                    <Skeleton variant="rounded" height={36} />
                                </div>
                                <div className="d-flex justify-content-between  py-2">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                                <div className="d-flex justify-content-between  py-3">
                                    {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Modal show={open} onHide={() => setOpen(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {isInfoHeading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre className="text-sm font-mono">{JSON.stringify(isInfoData, null, 2)}</pre>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default WebHook