import React, { useEffect, useState } from "react";
import NavBar2 from "../NavBar2";
import { apis } from "../../apis";
import axios from "axios";
import Cookies from "js-cookie";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_twilio } from "../../redux/slice/createtwilio";
import Adminaside from "../../components/asides/Adminaside";
import { Bounce, toast } from "react-toastify";
import SelectSingle from "../../components/input/Select";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/comman/admin_header";
import { Col, Row } from "react-bootstrap";
import { get_twiliofacilityphone } from "../../redux/slice/twiliofacilityphone";
import "../../module_css/createtwiliofacilityphone.css"

const CreateTwilioFacilityPhone = () => {
  const formInit = {
    name: "",
    phone: "",
    twilio_creds: "",
  };
  const navigate = useNavigate();
  const twiliocredid = useSelector((state) => state?.twilio);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [resError, setResError] = useState("");
  const [error, setError] = useState(formInit);
  const [successToast, setSuccessToast] = useState(false);
  const [errToast, setErrToast] = useState(false);
  let token = Cookies.get("token");

  useEffect(() => {
    if (!twiliocredid?.status && !twiliocredid?.loading) {
      dispatch(get_twilio({ token }));
    }
  }, [token, twiliocredid, dispatch]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let isError = false;
      let errorList = {};
      // Object.keys(formData).forEach((key) => {
      //   if (formData[key] === "") {
      //     errorList[key] = `${key} is required!`;
      //     setErrToast(true);
      //     isError = true;
      //   }
      // });

      Object.keys(formData)?.forEach((it) => {
        if (formData[it] === "") {
          let formattedKey = it?.split('_').join(' '); 
      
          formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
          errorList[it] = `${formattedKey} is required!`;
      
          setErrToast(true);
          isError = true;
        }
      });
      setError(errorList);
      if (isError) return;

      const headers = { token: token };
      await axios.post(apis.TWILIO_FACILITY_PHONE, formData, { headers }).then((res)=>{
        if(res.status){
        navigate("/admin/twiliofacilityphone");

        }
      })
      setSuccessToast(true);
      dispatch(get_twiliofacilityphone({ token }));
      // setTimeout(() => {
      //   navigate("/admin/twiliofacilityphone");
      // }, 1000)
    } catch (error) {
      setLoading(false);
      setResError(error?.response?.data?.msg || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const toastStyle = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };

  useEffect(() => {
    if (successToast) {
      toast.success("Create Facility Phone Created Successfully", toastStyle);
    }
  }, [successToast]);

  useEffect(() => {
    if (errToast) {
      Object.values(error).forEach((msg) => {
        toast.error(msg, toastStyle);
      });
      setTimeout(() => setErrToast(false), 1000);
    }
  }, [errToast]);

  useEffect(() => {
    if (resError) {
      toast.error(resError, toastStyle);
    }
  }, [resError]);

  return (
    <>
      <div className="admin-dashboard">
        <div className="admin-nav">
          <NavBar2 />
        </div>
        <div className="admin-container">
          <div className="aside text-center align-item-center">
            <Adminaside />
          </div>
          <AdminHeader backTitle="Dashboard" backPath="/admin" action_path="/admin/twiliofacilityphone" action="All Facility Phone" />
          <div className="create-user border m-auto twiliofacilityphoneuser" style={{ height: '77.3%' }}>
            <form onSubmit={handleSubmit}>
              <h1 className="text-center">Create Twilio Facility Phone</h1>
              <div className="container">
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="py-2">
                    <TextField
                      id="outlined-required"
                      label="Phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Col>
                </Row>
                <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="py-2">
                    <SelectSingle
                      data={twiliocredid?.twilio_data}
                      value={formData.twilio_creds}
                      name="twilio_creds"
                      label="Select Twilio"
                      handleChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-2">
                  <button type={loading ? "button" : "submit"} className="btn btn-success">
                    {loading ? "Loading..." : "Create Facility Phone"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTwilioFacilityPhone;
