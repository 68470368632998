import React, { useState } from "react";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import ExportJSON from "./index";
import { useSelector } from "react-redux";
const SpecificFields = ({
  isSpecificFieldsModal,
  setIsSpecificFieldsModal,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const all_csv_shifts = useSelector((state) => state.all_csv_shifts);
  const options = [
    "Facility name",
    // "FullName",
    // "Email",
    "Shift id",
    "Check in",
    "Check out",
    // "ManualCheckOutTime",
    "Facility hours",
    "Phone",
    "Address",
    // "VisitType",
    // "ResidentName",
    // "RoomNumber",
    // "KioskName",
    "Agency",
    "Shift time",
    "Position",
    // "Vendor",
    // "Department",
    // "Vaccination",
    // "CheckInTemperature",
    // "CheckOutTemperature",
    // "MidShiftTemperatures",
  ];
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    );
  };
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions([]);
    }
  };
  return (
    <Modal
      show={isSpecificFieldsModal}
      onHide={() => setIsSpecificFieldsModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Add Specific Fields
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Select All Checkbox */}
        <Form.Group as={Row}>
          <Col>
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectedOptions.length === options.length}
              onChange={handleSelectAllChange}
            />
          </Col>
        </Form.Group>
        {/* Individual Option Checkboxes */}
        <Row className="mb-3 mt-3">
          {options?.map((option, index) => (
            <Col xs={12} sm={4} md={4} key={index} className="mb-3">
              <Form.Check
                type="checkbox"
                value={option}
                label={option}
                checked={selectedOptions.includes(option)}
                onChange={handleCheckboxChange}
              />
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-center mt-4">
          <ExportJSON
            data={all_csv_shifts?.csvdata?.data}
            selectedField={selectedOptions}
            isSpecifiedFields={true}
            setIsSpecificFieldsModal={setIsSpecificFieldsModal}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SpecificFields;