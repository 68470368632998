import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function BasicSelect(props) {
    const { data, name, label, handleChange, value, showPhone } = props;

    const handleClearSelection = () => {
        handleChange({ target: { name, value: '' } });
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value || ''}
                    label={label}
                    name={name}
                    onChange={handleChange}
                    sx={{ paddingRight: '30px' }}
                >
                    {data?.map((item, key) => (
                        <MenuItem key={key} value={item?._id || item}>
                            {item?.name ? `${item?.name}` : item}
                            {showPhone && item?.phone && (
                                <span style={{ fontSize: '12px' }}>
                                    {`(${item?.phone})`}
                                </span>
                            )}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Show the cross icon only when a value is selected */}
            {value && (
                <IconButton
                    onClick={handleClearSelection}
                    size="small"
                    sx={{
                        position: 'absolute',
                        right: 30,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Box>
    );
}